import React, { useEffect, useRef, useState, KeyboardEvent } from 'react';
import { InputBase, InputBaseProps } from './input_base';
import { IconXMarkOutline } from './icons';

export interface TagOption {
  label: string;
  value: string | number;
}

interface InputTagSelectProps extends InputBaseProps {
  options?: TagOption[];
  selectedTags: TagOption[];
  onChange: (tags: TagOption[]) => void;
  placeholder?: string;
  allowCustomTags?: boolean;
  size?: "input-sm" | "input-md" | "input-lg";
  disabled?: boolean;
}

export const InputTagSelect: React.FC<InputTagSelectProps> = ({
  options = [],
  allowCustomTags = false,
  size,
  disabled,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [isShaking, setIsShaking] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const activeItemRef = useRef<HTMLAnchorElement>(null);

  const filteredOptions = options.length > 0
    ? options.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase()) &&
      !props.selectedTags.some(tag => tag.value === option.value)
    )
    : [];

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (activeItemRef.current) {
      activeItemRef.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth'
      });
    }
  }, [focusedIndex]);

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  const isDuplicateTag = (label: string): boolean => {
    return props.selectedTags.some(
      tag => tag.label.toLowerCase() === label.toLowerCase()
    );
  };

  const handleDuplicateAttempt = () => {
    setIsShaking(true);
    setTimeout(() => setIsShaking(false), 200);
  };

  const addTag = (option: TagOption) => {
    if (isDuplicateTag(option.label)) {
      handleDuplicateAttempt();
      return;
    }
    props.onChange([...props.selectedTags, option]);
    setInputValue('');
    setShowDropdown(false);
    setFocusedIndex(0);
    inputRef.current?.focus();
  };

  const removeTag = (tagToRemove: TagOption) => {
    props.onChange(props.selectedTags.filter(tag => tag.value !== tagToRemove.value));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ';') {
      e.preventDefault();
      if (options.length > 0) {
        if (filteredOptions[focusedIndex]) {
          addTag(filteredOptions[focusedIndex]);
        }
        return;
      }

      const lastInput = inputValue.split(';').pop()?.trim() || '';

      if (isDuplicateTag(lastInput)) {
        handleDuplicateAttempt();
        return;
      }

      const matchingOption = options.find(
        opt => opt.label.toLowerCase() === lastInput.toLowerCase()
      );

      if (matchingOption && !props.selectedTags.some(tag => tag.value === matchingOption.value)) {
        addTag(matchingOption);
      } else if (allowCustomTags) {
        const newTag: TagOption = {
          label: lastInput,
          value: lastInput
        };
        addTag(newTag);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex(prev => Math.min(prev + 1, filteredOptions.length - 1));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex(prev => Math.max(prev - 1, 0));
    }
  };

  return (
    <InputBase {...props}>
      <div ref={wrapperRef} className="relative">
        <div 
          className={`input input-bordered flex flex-wrap gap-2 p-2 w-full
            ${props.errors.length > 0 ? 'input-error' : ''} 
            ${isShaking ? 'animate-shake' : ''}
            ${size || ''}
            ${disabled ? 'input-disabled' : ''}
            min-h-[2.5rem] h-auto
          `}
        >
          {props.selectedTags.map((tag) => (
            <div
              key={tag.value}
              className="flex items-center gap-1 px-2 py-1 bg-base-200 rounded-full"
            >
              <span>{tag.label}</span>
              <button
                type="button"
                onClick={() => removeTag(tag)}
                className="hover:text-error"
                disabled={disabled}
              >
                <IconXMarkOutline className="w-4 h-4" />
              </button>
            </div>
          ))}
          <input
            ref={inputRef}
            type="text"
            className="flex-1 min-w-[8rem] bg-transparent outline-none"
            value={inputValue}
            placeholder={props.placeholder}
            onChange={(e) => {
              setInputValue(e.target.value);
              setShowDropdown(true);
            }}
            onFocus={() => setShowDropdown(true)}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
        </div>

        {showDropdown && filteredOptions.length > 0 && (
          <div className="dropdown-content drop-shadow-xl rounded-box max-h-40 overflow-y-auto absolute w-full z-10 mt-2">
            <ul className="menu bg-base-100 rounded-box">
              {filteredOptions.map((option, index) => (
                <li key={option.value}>
                  <a
                    ref={index === focusedIndex ? activeItemRef : null}
                    href="#"
                    className={`${focusedIndex === index ? 'bg-base-200' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      addTag(option);
                    }}
                  >
                    {option.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </InputBase>
  );
}; 