import {
  Body,
  Container,
  Head,
  Heading,
  Html,
  Img,
  Link,
  Text,
} from "@react-email/components";
import * as React from "react";
console.log(React.version)
import { Style } from './style';

export interface EmailOTPTemplateProps {
  otp?: string;
}

export const EmailOTPTemplate = (props: EmailOTPTemplateProps) => (
  <Html>
    <Head />
    <Body style={Style.main}>
      <Container style={Style.container}>
        <Heading style={Style.h1}>Vigil - OTP</Heading>
        <Text style={{ ...Style.text, marginBottom: "14px" }}>
          Please use the following OTP to continue.
        </Text>
        <code style={Style.code}>{props.otp}</code>
        <Text
          style={{
            ...Style.text,
            color: "#ababab",
            marginTop: "14px",
            marginBottom: "16px",
          }}
        >
          If you did not perform an action on the Vigil platform, please ignore this email.
        </Text>

        <Img
          src={`https://app-web.acc.vigil.tiental.com/static_content/emails/logo.png`}
          width="32"
          height="32"
          alt="Vigil Logo"
        />
        <Text style={Style.footer}>
          <Link
            href="https://app-website.acc.vigil.tiental.com/"
            target="_blank"
            style={{ ...Style.link, color: "#898989" }}
          >
            VigilSecure.net
          </Link>
          , the next generation platform
          <br />
          for private security.
        </Text>
      </Container>
    </Body>
  </Html>
);

EmailOTPTemplate.PreviewProps = {
  otp: '151233'
} as EmailOTPTemplateProps;

export default EmailOTPTemplate;
