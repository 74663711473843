import { sqliteTable, text, uniqueIndex } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { relations } from "drizzle-orm";
import { devices } from "./bean_device";
import { flowInstances } from "./bean_flow_instance";

export const linkFlowInstanceDevice = sqliteTable('link_flow_instance_device', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidDevice: text('uuid_device').notNull().references(() => devices.uuid),
  uuidFlowInstance: text('uuid_flow_instance').notNull().references(() => flowInstances.uuid),
}, (table) => ({
  flowInstanceDeviceIdx: uniqueIndex('flow_instance_device_idx').on(table.uuidOrganization, table.uuidDevice, table.uuidFlowInstance)
}));

export const linkFlowInstanceDeviceRelations = relations(linkFlowInstanceDevice, ({ one }) => ({
  organization: one(organizations, {
    fields: [linkFlowInstanceDevice.uuidOrganization],
    references: [organizations.uuid],
  }),
  device: one(devices, {
    fields: [linkFlowInstanceDevice.uuidDevice],
    references: [devices.uuid],
  }),
  flowInstance: one(flowInstances, {
    fields: [linkFlowInstanceDevice.uuidFlowInstance],
    references: [flowInstances.uuid],
  }),
}));