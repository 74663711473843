import { useState, useEffect, useRef } from 'react';
import { useInterval } from './use_interval';

type Callback<T> = () => Promise<T>;

interface UseFetchResult<T> {
  result: T | null;
  loading: boolean;
  error: string;
  refetch: (background?: boolean) => Promise<void>;
}

/**
 * Custom hook to handle asynchronous data fetching
 *
 * @template T - The type of data returned by the fetch callback
 * @param {Object} params - Configuration parameters
 * @param {Callback<T>} params.callback - Async function that fetches the data
 * @param {Object} [params.dependencies] - Optional dependency arrays to control when fetching occurs
 * @param {any[]} [params.dependencies.background] - Dependencies that trigger background refetches
 * @param {any[]} [params.dependencies.normal] - Dependencies that trigger normal fetches with loading state
 * @param {number} [params.intervalTime] - Optional interval in ms to poll for new data
 * @param {boolean} [params.pauseIntervalOnError] - Whether to pause polling when an error occurs
 * @param {() => void} [params.onCalled] - Optional callback invoked after successful fetches
 * @returns {UseFetchResult<T>} Object containing:
 *   - result: The fetched data or null
 *   - loading: Boolean indicating if a non-background fetch is in progress
 *   - error: Error message string if the last fetch failed
 *   - refetch: Function to manually trigger a new fetch
 */
export const useFetch = <T>(params: {
  callback: Callback<T>,
  dependencies?: { background: any[], normal: any[] },
  intervalTime?: number,
  pauseIntervalOnError?: boolean,
  onCalled?: () => void
}): UseFetchResult<T> => {
  const isInitialMount = useRef(true);
  const [result, setResult] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [shouldRunInterval, setShouldRunInterval] = useState(true);

  async function callBackground() {
    try {
      setError("");
      const data = await params.callback();
      setResult(data);
      if (params.onCalled) params.onCalled();
      setShouldRunInterval(true);
    } catch (error: any) {
      if (params.pauseIntervalOnError) setShouldRunInterval(false);
      setError(error.message);
      throw error;
    }
  }

  async function call(background?: boolean) {
    try {
      if (!background) setLoading(true);
      await callBackground();
    } finally {
      setLoading(false);
    }
  };

  // Effect for normal (non-background) execution
  // Runs on mount or when normal dependencies change
  useEffect(() => { call() }, params.dependencies?.normal ?? []);

  // Effect for background execution
  // Runs when background dependencies change, skipping the initial mount
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      callBackground();
    }
  }, params.dependencies?.background ?? []);

  // Effect for interval-based execution
  if (params.intervalTime) {
    useInterval(
      callBackground,
      shouldRunInterval ? params.intervalTime : null,
      false
    );
  }

  return { result, loading, error, refetch: call };
};