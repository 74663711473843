import { ICreator } from "./type_creator";
import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { flowInstances } from "./bean_flow_instance";
import { relations } from "drizzle-orm";

export const flowInstanceLogs = sqliteTable('flow_instance_logs', {
  ...schemaBase(),
  uuidFlowInstance: text('uuid_flow_instance').notNull().references(() => flowInstances.uuid),
  creator: customJsonColumn<ICreator>('creator').notNull(),
  tags: customJsonColumn<string[]>('tags').notNull(),
  data: customJsonColumn<Record<string, any>>('data').notNull(),
});

export const flowInstanceLogsRelations = relations(flowInstanceLogs, ({ one }) => ({
  flowInstance: one(flowInstances, {
    fields: [flowInstanceLogs.uuidFlowInstance],
    references: [flowInstances.uuid],
  }),
}));
