// TODO: This flow is very messy, it can be cleaned up

import { FlowConfig } from "../base/config";
import { Flow } from "../base/config_types";
import { FLOW_DEVICE_USER_SIGN_IN_ID, FlowDeviceUserSignIn, FlowDeviceUserSignInParameters } from "./flow_device_user_sign_in";
import { FLOW_DEVICE_USER_SIGN_OUT_ID, FlowDeviceUserSignOutParameters, FlowDeviceUserSignOut } from "./flow_device_user_sign_out";

const TAG_ADOPTED_FLOW = "adopted_flow"

interface AdoptedFlowLog {
  msg: string;
  flow: Flow;
}

interface FlowDeviceAdoptSiteSpecificFlowsParameters {
  schedule: "0 12 * * *";
  duration_hours: number;
  radius_from_beacons_meters: number;
  // all_devices: boolean;
  device_uuids: string[];
}

export const FlowDeviceAdoptSiteSpecificFlows: FlowConfig<FlowDeviceAdoptSiteSpecificFlowsParameters> = {
  config: {
    id: "device_adopt_site_specific_flows",
    name: "Device Adopt Site Specific Flows",
    description: "Flow that runs on a device to adopt site specific flows. Note: If you make 'All Devices' true, it will override the 'Devices' parameter. If you do not select any devices, the flow will not run.",
    parameters: [
      {
        id: "schedule",
        name: "Schedule",
        description: "Schedule to adopt the flows",
        required: true,
        type: "cron",
        memberLink: null
      },
      {
        id: "duration_hours",
        name: "Duration (hours)",
        description: "For how long should the flows be adopted",
        required: true,
        type: "integer",
        memberLink: null
      },
      {
        id: "radius_from_beacons_meters",
        name: "Radius From Beacons (meters)",
        description: "The radius from the beacons to adopt the flows",
        required: true,
        type: "integer",
        validation: {
          min: 10,
          max: 500,
          step: 10
        },
        memberLink: null
      },
      // {
      //   id: "all_devices",
      //   name: "All Devices",
      //   description: "If true, the flow will be executed on all devices, even if new devices get added in the future",
      //   required: true,
      //   type: "boolean",
      //   memberLink: null
      // },
      {
        id: "device_uuids",
        name: "(Optional) Devices",
        description: "List of devices that will adopt the site specific flows",
        required: true,
        type: "array",
        validation: {
          duplicateItems: false,
          options: async (context) => {
            return await context.organization.getDevices().then(devices => devices.map(device => ({ label: device.serial, value: device.uuid })))
          }
        },
        memberLink: 'device'
      }
    ],
  },
  device: {
    screens: [],
    createInstance: async (context, parameters) => {
      const { vigil, flow, device } = context;

      const _device = await device.getDevice()

      // If all_devices is false, and the device is not in the list, do not create an instance
      // if (!parameters.all_devices && !parameters.device_uuids.includes(_device.uuid)) return null
      if (!parameters.device_uuids.includes(_device.uuid)) return null

      // Create the flow instance if it should run now
      const timeslot = vigil.getTimeslotFromCron(parameters.schedule, parameters.duration_hours * 60 * 60 * 1000);
      const flowInstance = await flow.getInstance(timeslot.start, timeslot.end, new Date())
      if (!flowInstance) return null

      await flowInstance.linkDevices([_device.uuid])

      return flowInstance
    },
    tickInstance: async (context, flowInstance) => {
      // Extract required context objects
      const { organization, device, vigil } = context;

      const _device = await device.getDevice()

      const parameters = flowInstance.flowSnapshot.parameterValues as FlowDeviceAdoptSiteSpecificFlowsParameters;

      const radiusFromBeaconsMeters = parameters.radius_from_beacons_meters

      // Exit if device has no location
      const deviceLocation = await device.getLocation()
      if (!deviceLocation) return

      // Exit if organization has no beacons
      const beacons = await organization.getBeacons()
      if (beacons.length === 0) return

      // Get beacons within configured radius, sorted by distance
      const sortedBeacons = beacons
        .filter(beacon => vigil.distance(beacon, deviceLocation) <= radiusFromBeaconsMeters)
        .sort((a, b) => vigil.distance(a, deviceLocation) - vigil.distance(b, deviceLocation))
      if (sortedBeacons.length === 0) return

      // Process beacons in order of proximity
      for (const beacon of sortedBeacons) {
        // Skip beacons with no linked sites
        const sites = await organization.getSitesLinkedToBeacon(beacon.uuid)
        if (sites.length === 0) continue

        // Process sites linked to current beacon
        for (const site of sites) {
          // Skip sites with no linked flows
          const flows = await site.getLinkedFlows()
          if (!flows || flows.length === 0) continue

          // Execute all flows for first valid site found
          for (const flow of flows) {
            const modifiedFlowTag = `${TAG_ADOPTED_FLOW}:${flow.id}`
            // TODO: Can add other flows here
            if (flow.id === FLOW_DEVICE_USER_SIGN_IN_ID) {
              const parameters = flow.parameterValues as FlowDeviceUserSignInParameters
              const _flowInstance = await FlowDeviceUserSignIn.device.createInstanceFromAnotherFlow!(context, {
                ...parameters,
                device_uuids: parameters.device_uuids.concat([_device.uuid])
              })
              if (!_flowInstance) continue
              const _log = await _flowInstance.existsDeviceCreatedLogs([modifiedFlowTag], [_device.uuid])
              if (_log) continue
              await flowInstance.createLog<AdoptedFlowLog>({ msg: "Adopted flow", flow: flow }, [modifiedFlowTag])
            }
            if (flow.id === FLOW_DEVICE_USER_SIGN_OUT_ID) {
              const parameters = flow.parameterValues as FlowDeviceUserSignOutParameters
              const _flowInstance = await FlowDeviceUserSignOut.device.createInstanceFromAnotherFlow!(context, {
                ...parameters,
                device_uuids: parameters.device_uuids.concat([_device.uuid])
              })
              if (!_flowInstance) continue
              const _log = await _flowInstance.existsDeviceCreatedLogs([modifiedFlowTag], [_device.uuid])
              if (_log) continue
              await flowInstance.createLog<AdoptedFlowLog>({ msg: "Adopted flow", flow: flow }, [modifiedFlowTag])
            }
          }
        }
      }
    },
  },
  server: {
    createInstance: async (context, parameters) => {
      const { vigil, flow, device } = context;

      const _device = await device.getDevice()

      // If all_devices is false, and the device is not in the list, do not create an instance
      // if (!parameters.all_devices && !parameters.device_uuids.includes(_device.uuid)) return null
      if (!parameters.device_uuids.includes(_device.uuid)) return null

      // Create the flow instance if it should run now
      const timeslot = vigil.getTimeslotFromCron(parameters.schedule, parameters.duration_hours * 60 * 60 * 1000);
      const flowInstance = await flow.getInstance(timeslot.start, timeslot.end, new Date())
      if (!flowInstance) return null

      await flowInstance.linkDevices([_device.uuid])

      return flowInstance
    },
    tickInstance: async (context, flowInstance) => {
      // Extract required context objects
      const { organization, device, vigil } = context;

      const _device = await device.getDevice()

      const parameters = flowInstance.flowSnapshot.parameterValues as FlowDeviceAdoptSiteSpecificFlowsParameters;

      const radiusFromBeaconsMeters = parameters.radius_from_beacons_meters

      // Exit if device has no location
      const deviceLocation = await device.getLocation()
      if (!deviceLocation) return

      // Exit if organization has no beacons
      const beacons = await organization.getBeacons()
      if (beacons.length === 0) return

      // Get beacons within configured radius, sorted by distance
      const sortedBeacons = beacons
        .filter(beacon => vigil.distance(beacon, deviceLocation) <= radiusFromBeaconsMeters)
        .sort((a, b) => vigil.distance(a, deviceLocation) - vigil.distance(b, deviceLocation))
      if (sortedBeacons.length === 0) return

      // Process beacons in order of proximity
      for (const beacon of sortedBeacons) {
        // Skip beacons with no linked sites
        const sites = await organization.getSitesLinkedToBeacon(beacon.uuid)
        if (sites.length === 0) continue

        // Process sites linked to current beacon
        for (const site of sites) {
          // Skip sites with no linked flows
          const flows = await site.getLinkedFlows()
          if (!flows || flows.length === 0) continue

          // Execute all flows for first valid site found
          for (const flow of flows) {
            const modifiedFlowTag = `${TAG_ADOPTED_FLOW}:${flow.id}`
            // TODO: Can add other flows here
            if (flow.id === FLOW_DEVICE_USER_SIGN_IN_ID) {
              const parameters = flow.parameterValues as FlowDeviceUserSignInParameters
              const _flowInstance = await FlowDeviceUserSignIn.device.createInstanceFromAnotherFlow!(context, {
                ...parameters,
                device_uuids: parameters.device_uuids.concat([_device.uuid])
              })
              if (!_flowInstance) continue
              const _log = await _flowInstance.existsServerCreatedLogs([modifiedFlowTag])
              if (_log) continue
              await flowInstance.createLog<AdoptedFlowLog>({ msg: "Adopted flow", flow: flow }, [modifiedFlowTag])
            }
            if (flow.id === FLOW_DEVICE_USER_SIGN_OUT_ID) {
              const parameters = flow.parameterValues as FlowDeviceUserSignOutParameters
              const _flowInstance = await FlowDeviceUserSignOut.device.createInstanceFromAnotherFlow!(context, {
                ...parameters,
                device_uuids: parameters.device_uuids.concat([_device.uuid])
              })
              if (!_flowInstance) continue
              const _log = await _flowInstance.existsServerCreatedLogs([modifiedFlowTag])
              if (_log) continue
              await flowInstance.createLog<AdoptedFlowLog>({ msg: "Adopted flow", flow: flow }, [modifiedFlowTag])
            }
          }
        }
      }
    }
  }
}
