import {
  Body,
  Button,
  Container,
  Head,
  Heading,
  Html,
  Img,
  Link,
  Text,
} from "@react-email/components";
import * as React from "react";
console.log(React.version)
import { Style } from './style';

export interface EmailInviteTemplateProps {
  sourceUserName: string;
  sourceUserEmail: string;
  targetUserEmail: string;
  organizationName: string;
  inviteLink: string;
}

const EmailInviteTemplate = (props: EmailInviteTemplateProps) => (
  <Html>
    <Head />
    <Body style={Style.main}>
      <Container style={Style.container}>
        <Heading style={Style.h1}>Vigil - Join {props.organizationName}</Heading>
        <Text style={{ ...Style.text, marginBottom: "14px" }}>
          Hello,
        </Text>
        <Text style={{ ...Style.text, marginBottom: "14px" }}>
          {props.sourceUserName} ({props.sourceUserEmail}) has invited you to join the {props.organizationName} organization on Vigil.
        </Text>
        <Button style={{ ...Style.button, marginBottom: "14px"}} href={props.inviteLink}>
          Join {props.organizationName}
        </Button>

        <Img
          src={`https://app-web.acc.vigil.tiental.com/static_content/emails/logo.png`}
          width="32"
          height="32"
          alt="Vigil Logo"
        />
        <Text style={Style.footer}>
          <Link
            href="https://app-website.acc.vigil.tiental.com/"
            target="_blank"
            style={{ ...Style.link, color: "#898989" }}
          >
            VigilSecure.net
          </Link>
          , the next generation platform 
          <br />
          for private security.
        </Text>
      </Container>
    </Body>
  </Html>
);

EmailInviteTemplate.PreviewProps = {
  sourceUserName: 'Kobus Van der Walt',
  sourceUserEmail: 'kobusvdwalt9@gmail.com',
  targetUserEmail: 'wihandb@gmail.com',
  inviteLink: 'https://app-web.acc.vigil.tiental.com/profile/organizations',
  organizationName: 'KSec'
} as EmailInviteTemplateProps;

export default EmailInviteTemplate;
