import { IBaseSyncable } from "tt-coms";
import { integer, sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { organizations } from "./bean_organization";
import { flowInstances } from "./bean_flow_instance";
import { linkFlowDevice } from "./bean_link_flow_device";
import { linkFlowUser } from "./bean_link_flow_user";
import { linkFlowSite } from "./bean_link_flow_site";

export interface IFlow extends IBaseSyncable {
  uuidOrganization: string;
  name: string;
  id: string;
  version: number;
  parameterValues: Record<string, any>;
  parametersLabel: string;
}

export const flows = sqliteTable('flows', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  name: text('name').notNull(),
  id: text('id').notNull(),
  version: integer('version').notNull().default(0),
  parameterValues: customJsonColumn<Record<string, any>>('parameter_values').notNull(),
  parametersLabel: text('parameters_label').notNull(),
});

export const flowsRelations = relations(flows, ({ one, many }) => ({
  organization: one(organizations, {
    fields: [flows.uuidOrganization],
    references: [organizations.uuid],
  }),
  flowInstances: many(flowInstances),
  flowsToDevices: many(linkFlowDevice),
  flowsToUsers: many(linkFlowUser),
  flowsToSites: many(linkFlowSite),
}));
