import { FlowArrayValidation, FlowContextWebApp, FlowCron, FlowParameter } from "vigil-flow";
import { InputText } from "../../../components/input_text";
import { InputCron } from "../../../components/input_cron";
import { Checkbox } from "../../../components/checkbox";
import { InputBase } from "../../../components/input_base";
import { InputTagSelect, TagOption } from "../../../components/input_tag_select";
import { useEffect, useState } from "react";
import cronstrue from 'cronstrue';

export type FlowParameterWithValues = FlowParameter<any> & { value: string | number | boolean | FlowCron | Array<any> };

export const formatParametersLabel = async (parameters: FlowParameterWithValues[], flowContext?: FlowContextWebApp) => {
  const formatParameterValue = async (parameter: FlowParameterWithValues, flowContext?: FlowContextWebApp) => {
    switch (parameter.type) {
      case 'cron':
        try {
          return cronstrue.toString(parameter.value as string);
        } catch (e) {
          return parameter.value;
        }
      case 'array':
        const validation = parameter.validation as FlowArrayValidation;
        if (validation && validation.options && flowContext) {
          const options = await validation.options(flowContext);
          const formattedValues = (parameter.value as Array<any>)
            .map(val => options.find(opt => opt.value === val)?.label || val);
          return formattedValues.length > 0 ? formattedValues.join(', ') : 'None';
        }
        return (parameter.value as Array<any>).length > 0 ? (parameter.value as Array<any>).join(', ') : 'None';
      case 'boolean':
        return parameter.value ? 'Yes' : 'No';
      default:
        return parameter.value;
    }
  };

  return await Promise.all(parameters
    .map(async (parameter) => {
      const formattedValue = await formatParameterValue(parameter, flowContext);
      return `${parameter.name}: ${formattedValue}`;
    })).then(values => values.join('; '));
};

export interface FlowParameterProps {
  parameter: FlowParameterWithValues
  stateFlowParameters: FlowParameterWithValues[];
  setFlowParameters: (parameters: FlowParameterWithValues[]) => void;
  onChange?: (parameter: FlowParameterWithValues) => void;
  className?: string;
  context?: FlowContextWebApp;
}

export function getDefaultFlowParameterValue(parameter: FlowParameter<any>): string | number | boolean | FlowCron | Array<any> {
  if (parameter.type == 'string') return '';
  if (parameter.type == 'integer') return 1;
  if (parameter.type == 'float') return 1.0;
  if (parameter.type == 'boolean') return false;
  if (parameter.type == 'cron') return '0 12 * * *';
  if (parameter.type == 'array') return [];
  return '';
}

export const FlowParameterStringInput: React.FC<FlowParameterProps> = (props) => {
  const value: string = props.parameter.value as string;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parameterIndex = props.stateFlowParameters.findIndex((p) => p.id === props.parameter.id);
    const newFlowParameters = [...props.stateFlowParameters];
    newFlowParameters[parameterIndex] = { ...newFlowParameters[parameterIndex], value: event.target.value };
    props.setFlowParameters(newFlowParameters);
    if (props.onChange) props.onChange(newFlowParameters[parameterIndex]);
  }

  return <div key={props.parameter.id} className={props.className}>
    <div className="font-bold text-sm">{props.parameter.name}</div>
    <div className="italic text-sm">{props.parameter.description}</div>
    <InputText
      className="w-44"
      inputType="text"
      labelText={''}
      value={value}
      onChange={onChange}
      errors={[]}
    />
  </div>
}

export const FlowParameterCronInput: React.FC<FlowParameterProps> = (props) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const value: FlowCron = props.parameter.value as FlowCron;

  const onChange = (cron: string) => {
    const parameterIndex = props.stateFlowParameters.findIndex((p) => p.id === props.parameter.id);
    const newFlowParameters = [...props.stateFlowParameters];
    newFlowParameters[parameterIndex] = { ...newFlowParameters[parameterIndex], value: cron };
    props.setFlowParameters(newFlowParameters);
    if (props.onChange) props.onChange(newFlowParameters[parameterIndex]);
  }

  const onValidationErrors = (errors: string[]) => {
    setValidationErrors(errors);
  }

  return <div key={props.parameter.id} className={props.className}>
    <div className="font-bold text-sm">{props.parameter.name}</div>
    <div className="italic text-sm mb-1">{props.parameter.description}</div>
    <InputCron
      cron={value}
      validationErrors={validationErrors}
      onValidationErrors={onValidationErrors}
      onChangeCron={onChange}
    />
  </div>
}

export const FlowParameterFloatInput: React.FC<FlowParameterProps> = (props) => {
  const value: number = props.parameter.value as number;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parameterIndex = props.stateFlowParameters.findIndex((p) => p.id === props.parameter.id);
    const newFlowParameters = [...props.stateFlowParameters];
    newFlowParameters[parameterIndex] = { ...newFlowParameters[parameterIndex], value: parseFloat(event.target.value) };
    props.setFlowParameters(newFlowParameters);
    if (props.onChange) props.onChange(newFlowParameters[parameterIndex]);
  }

  return <div key={props.parameter.id} className={props.className}>
    <div className="font-bold text-sm">{props.parameter.name}</div>
    <div className="italic text-sm">{props.parameter.description}</div>
    <InputText
      className="w-44"
      inputType="numberFloat"
      labelText={''}
      value={value}
      onChange={onChange}
      errors={[]}
    />
  </div>
}

export const FlowParameterIntegerInput: React.FC<FlowParameterProps> = (props) => {
  const value: number = props.parameter.value as number;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parameterIndex = props.stateFlowParameters.findIndex((p) => p.id === props.parameter.id);
    const newFlowParameters = [...props.stateFlowParameters];
    newFlowParameters[parameterIndex] = { ...newFlowParameters[parameterIndex], value: parseInt(event.target.value) };
    props.setFlowParameters(newFlowParameters);
    if (props.onChange) props.onChange(newFlowParameters[parameterIndex]);
  }

  return <div key={props.parameter.id} className={props.className}>
    <div className="font-bold text-sm">{props.parameter.name}</div>
    <div className="italic text-sm">{props.parameter.description}</div>
    <InputText
      className="w-44"
      inputType="numberInt"
      labelText={''}
      value={value}
      onChange={onChange}
      errors={[]}
    />
  </div>
}

export const FlowParameterBooleanInput: React.FC<FlowParameterProps> = (props) => {
  const value: boolean = props.parameter.value as boolean;

  const onToggle = (checked: boolean) => {
    const parameterIndex = props.stateFlowParameters.findIndex((p) => p.id === props.parameter.id);
    const newFlowParameters = [...props.stateFlowParameters];
    newFlowParameters[parameterIndex] = { ...newFlowParameters[parameterIndex], value: checked };
    props.setFlowParameters(newFlowParameters);
    if (props.onChange) props.onChange(newFlowParameters[parameterIndex]);
  }

  return <div key={props.parameter.id} className={props.className}>
    <div className="font-bold text-sm">{props.parameter.name}</div>
    <div className="italic text-sm mb-1">{props.parameter.description}</div>
    <InputBase labelText={''} errors={[]}>
      <Checkbox
        key={props.parameter.id}
        label={props.parameter.name}
        checked={value}
        toggle={onToggle}
      />
    </InputBase>
  </div>
}

export const FlowParameterArrayInput: React.FC<FlowParameterProps> = (props) => {
  const [options, setOptions] = useState<Array<{ value: string, label: string }>>([]);
  const [allowCustomTags, setAllowCustomTags] = useState(false);
  const validation = props.parameter.validation as FlowArrayValidation;
  const value: Array<any> = props.parameter.value as Array<any>;

  useEffect(() => {
    const fetchOptions = async () => {
      if (!props.context) return;
      if (!validation || !validation.options) {
        setAllowCustomTags(true);
        return;
      }
      const opts = await validation.options(props.context);
      setOptions(opts);
    };
    fetchOptions();
  }, [props.context, validation]);

  const selectedTags = value.map(v => {
    const matchingOption = options.find(opt => opt.value === v);
    return {
      label: matchingOption ? matchingOption.label : v.toString(),
      value: v
    };
  });

  const onChange = (tags: TagOption[]) => {
    const parameterIndex = props.stateFlowParameters.findIndex((p) => p.id === props.parameter.id);
    const newFlowParameters = [...props.stateFlowParameters];
    newFlowParameters[parameterIndex] = { ...newFlowParameters[parameterIndex], value: tags.map(t => t.value) };
    props.setFlowParameters(newFlowParameters);
    if (props.onChange) props.onChange(newFlowParameters[parameterIndex]);
  }

  return <div key={props.parameter.id} className={props.className}>
    <div className="font-bold text-sm">{props.parameter.name}</div>
    <div className="italic text-sm">{props.parameter.description}</div>
    <InputTagSelect
      options={options}
      selectedTags={selectedTags}
      allowCustomTags={allowCustomTags}
      onChange={onChange}
      errors={[]}
      labelText={""}
    />
  </div>
}