export * from "./base/sdk_vigil";
export * from "./base/config";
export * from "./base/config_types";
export * from "./base/config_sdk_vigil";
export * from "./base/config_sdk_flow";
export * from "./base/config_sdk_device";
export * from "./base/config_sdk_organization";
export * from "./base/config_sdk_notification";

export * from "./configs/flow_device_adopt_site_specific_flows";
export * from "./configs/flow_device_user_sign_in";
export * from "./configs/flow_device_user_sign_out";
export * from "./configs/flow_send_public_report";

import { FlowDeviceAdoptSiteSpecificFlows } from "./configs/flow_device_adopt_site_specific_flows";
import { FlowDeviceUserSignIn } from "./configs/flow_device_user_sign_in";
import { FlowDeviceUserSignOut } from "./configs/flow_device_user_sign_out";
import { FlowSendPublicReport } from "./configs/flow_send_public_report";

export const VIGIL_FLOWS = [
  FlowDeviceAdoptSiteSpecificFlows,
  FlowDeviceUserSignIn,
  FlowDeviceUserSignOut,
  FlowSendPublicReport,
]
