import { sqliteTable, text, uniqueIndex } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { relations } from "drizzle-orm";
import { flowInstances } from "./bean_flow_instance";
import { sites } from "./bean_site";

export const linkFlowInstanceSite = sqliteTable('link_flow_instance_site', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidSite: text('uuid_site').notNull().references(() => sites.uuid),
  uuidFlowInstance: text('uuid_flow_instance').notNull().references(() => flowInstances.uuid),
}, (table) => ({
  flowInstanceSiteIdx: uniqueIndex('flow_instance_site_idx').on(table.uuidOrganization, table.uuidSite, table.uuidFlowInstance)
}));

export const linkFlowInstanceSiteRelations = relations(linkFlowInstanceSite, ({ one }) => ({
  organization: one(organizations, {
    fields: [linkFlowInstanceSite.uuidOrganization],
    references: [organizations.uuid],
  }),
  site: one(sites, {
    fields: [linkFlowInstanceSite.uuidSite],
    references: [sites.uuid],
  }),
  flowInstance: one(flowInstances, {
    fields: [linkFlowInstanceSite.uuidFlowInstance],
    references: [flowInstances.uuid],
  }),
}));