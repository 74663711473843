import React from 'react'
import { FlowConfig } from '../base/config';
console.log(typeof React)

export const FLOW_DEVICE_USER_SIGN_IN_ID = "device_user_sign_in"
const SOUND_ID = "sign_in_sound"
const SCREEN_ID = "user_sign_in_at|modal"

interface SignInLog {
  msg: string;
}

// TODO: Add user acknowledgement log
// interface UserAcknowledgementLog {
//   msg: string;
// }

const TAG_SIGN_IN = "sign_in"

export interface FlowDeviceUserSignInParameters {
  schedule: "0 12 * * *";
  duration_hours: number;
  site_uuids: string[];
  device_uuids: string[];
}

export const FlowDeviceUserSignIn: FlowConfig<FlowDeviceUserSignInParameters> = {
  config: {
    id: FLOW_DEVICE_USER_SIGN_IN_ID,
    name: "Device User Sign In",
    description: "Flow that runs on a device to alert user that they should sign in. Note: You need to atleast provide one site or device or both, if none is provided, the flow will not run.",
    parameters: [
      {
        id: "schedule",
        name: "Schedule",
        description: "Schedule to sign in the user",
        required: true,
        type: "cron",
        memberLink: null
      },
      {
        id: "duration_hours",
        name: "Duration (hours)",
        description: "For how long should the user be signed in",
        required: true,
        type: "integer",
        memberLink: null
      },
      {
        id: "site_uuids",
        name: "(Optional) Sites",
        description: "List of sites that this flow should be linked to",
        required: true,
        type: "array",
        memberLink: 'site',
        validation: {
          duplicateItems: false,
          options: async (context) => {
            return await context.organization.getSites().then(sites => sites.map(site => ({ label: site.name, value: site.uuid })))
          }
        }
      },
      {
        id: "device_uuids",
        name: "(Optional) Devices",
        description: "List of devices that this flow should be linked to",
        required: true,
        type: "array",
        validation: {
          duplicateItems: false,
          options: async (context) => {
            return await context.organization.getDevices().then(devices => devices.map(device => ({ label: device.serial, value: device.uuid })))
          }
        },
        memberLink: 'device'
      }
    ],
  },
  device: {
    screens: [
      {
        id: SCREEN_ID,
        code: (context) => {
          const c = context.components;
          return (<>
            <c.Modal isOpen={true} onClose={() => { }}>
              <c.Text variant="h3" margin={2} color="black" center={true}>Sign In Required</c.Text>
              <c.Text variant="p" margin={6} color="black" center={true}>User sign in is required in order to stop the alarm.</c.Text>
              <c.Button
                text="OK"
                variant="btn-primary"
                margin={4}
                onPress={async () => {
                  context.device.setAudio(null)
                  context.device.setScreen(null)
                  // TODO: Maybe add user acknowledgement log here
                }}
              />
            </c.Modal>
          </>)
        }
      }
    ],
    createInstance: async (context, parameters) => {
      const { vigil, flow, device } = context;

      // If device_uuids is provided, check if this device is in the list
      const _device = await device.getDevice()
      if (!parameters.device_uuids.includes(_device.uuid)) return null

      // Create the flow instance if it should run now
      const timeslot = vigil.getTimeslotFromCron(parameters.schedule, parameters.duration_hours * 60 * 60 * 1000);
      const flowInstance = await flow.getInstance(timeslot.start, timeslot.end, new Date())
      if (!flowInstance) return null
      
      await flowInstance.linkDevices([_device.uuid])
      await flowInstance.linkSites(parameters.site_uuids)

      return flowInstance
    },
    createInstanceFromAnotherFlow: async (context, parameters) => {
      const { vigil, flow, device } = context;

      // If device_uuids is provided, check if this device is in the list
      const _device = await device.getDevice()
      if (!parameters.device_uuids.includes(_device.uuid)) return null

      // Create the flow instance if it should run now
      const timeslot = vigil.getTimeslotFromCron(parameters.schedule, parameters.duration_hours * 60 * 60 * 1000);
      const flowInstance = await flow.getInstance(timeslot.start, timeslot.end, new Date())
      if (!flowInstance) return null
      
      await flowInstance.linkDevices([_device.uuid])
      await flowInstance.linkSites(parameters.site_uuids)
      await flowInstance.updateParameters(parameters)

      return flowInstance
    },
    tickInstance: async (context, flowInstance) => {
      // Destructure context
      const { device } = context;

      // If user is signed in, skip tick
      if (await device.getUser()) return

      const now = new Date();

      // Get current screen
      const screen = device.getScreen();

      // If there is no modal, or a different model, show modal
      if (!screen || screen.id != SCREEN_ID) {
        device.setAudio({ id: SOUND_ID, base64Audio: context.vigil.audioBase64.alert_1, loop: 50 });
        device.setScreen({ id: SCREEN_ID });
      }

      // Create log
      const lastLog = await flowInstance.getDeviceLastCreatedLog<SignInLog>([TAG_SIGN_IN], [(await device.getDevice()).uuid])

      // Only create log if it has been more than 10 minutes since the last log
      if (!lastLog || now > new Date(lastLog.datetime.valueOf() + 10 * 60 * 1000)) {
        await flowInstance.createLog<SignInLog>({ msg: "Sign in alert" }, [TAG_SIGN_IN])
      }
    },
  },
  server: {
    createInstance: async (context, parameters) => {
      const { vigil, flow, device } = context;

      // If device_uuids is provided, check if this device is in the list
      const _device = await device.getDevice()
      if (!parameters.device_uuids.includes(_device.uuid)) return null

      // Create the flow instance if it should run now
      const timeslot = vigil.getTimeslotFromCron(parameters.schedule, parameters.duration_hours * 60 * 60 * 1000);
      const flowInstance = await flow.getInstance(timeslot.start, timeslot.end, new Date())
      if (!flowInstance) return null

      await flowInstance.linkDevices([_device.uuid])
      await flowInstance.linkSites(parameters.site_uuids)

      return flowInstance
    },
    createInstanceFromAnotherFlow: async (context, parameters) => {
      const { vigil, flow, device } = context;

      // If device_uuids is provided, check if this device is in the list
      const _device = await device.getDevice()
      if (!parameters.device_uuids.includes(_device.uuid)) return null

      // Create the flow instance if it should run now
      const timeslot = vigil.getTimeslotFromCron(parameters.schedule, parameters.duration_hours * 60 * 60 * 1000);
      const flowInstance = await flow.getInstance(timeslot.start, timeslot.end, new Date())
      if (!flowInstance) return null
      
      await flowInstance.linkDevices([_device.uuid])
      await flowInstance.linkSites(parameters.site_uuids)
      await flowInstance.updateParameters(parameters)

      return flowInstance
    },
    tickInstance: async (context, flowInstance) => {
      const { device } = context;

      // If user is signed in, skip tick
      if (await device.getUser()) return

      const now = new Date();

      // If user has not signed in for 10 minutes, send notification
      const lastLog = await flowInstance.getServerLastCreatedLog<SignInLog>([TAG_SIGN_IN])
      if (now > new Date(flowInstance.dateTimeStart.valueOf() + 10 * 60 * 1000) && (!lastLog || now > new Date(lastLog.datetime.valueOf() + 10 * 60 * 1000))) {
        // Create log
        await flowInstance.createLog<SignInLog>({ msg: "Sign in missed" }, [TAG_SIGN_IN])
      }
    }
  }
}
