import { matchPath } from 'react-router-dom';

export const ROUTES = {
  ROUTE_APP_BASE: '',
  ROUTE_BASE: '',

  // Public routes
  ROUTE_PUBLIC: '/public',
  ROUTE_PUBLIC_REPORT: '/public/report/:report_uuid',
  ROUTE_PUBLIC_REPORT_OVERVIEW: '/public/report/:report_uuid/overview',
  ROUTE_PUBLIC_REPORT_EVENTS: '/public/report/:report_uuid/events',
  ROUTE_PUBLIC_REPORT_SITES: '/public/report/:report_uuid/sites',
  ROUTE_PUBLIC_REPORT_DEVICES: '/public/report/:report_uuid/devices',
  ROUTE_PUBLIC_REPORT_EVENT: '/public/report/:report_uuid/event/:uuid',
  ROUTE_PUBLIC_REPORT_SITE: '/public/report/:report_uuid/site/:uuid',
  ROUTE_PUBLIC_REPORT_DEVICE: '/public/report/:report_uuid/device/:uuid',
  ROUTE_PUBLIC_REPORT_DEVICE_LOGS_ACTIVITY: '/public/report/:report_uuid/device/:uuid/logs_activity',
  ROUTE_PUBLIC_REPORT_DEVICE_LOGS_STATE: '/public/report/:report_uuid/device/:uuid/logs_state',
  ROUTE_PUBLIC_BEACONS: '/public/beacons',

  // FTUE routes
  ROUTE_TOOLBOX: '/toolbox',
  ROUTE_SIGN_IN_REQUEST: '/sign_in_request',
  ROUTE_SIGN_IN_CONFIRM: '/sign_in_confirm',
  ROUTE_SIGN_UP: '/sign_up',
  ROUTE_USER_CREATE: '/user_create',
  ROUTE_USER_CREATE_EMAIL_REQUEST: '/user_create_email_request',
  ROUTE_USER_CREATE_EMAIL_CONFIRM: '/user_create_email_confirm',
  ROUTE_USER_CREATE_MOBILE_REQUEST: '/user_create_mobile_request',
  ROUTE_USER_CREATE_MOBILE_CONFIRM: '/user_create_mobile_confirm',
  ROUTE_USER_MERGE: '/user_merge',
  ROUTE_VERIFY_EMAIL_REQUEST: '/verify_email_request',
  ROUTE_VERIFY_EMAIL_CONFIRM: '/verify_email_confirm',
  ROUTE_VERIFY_MOBILE: '/verify_mobile',

  // Profile routes
  ROUTE_PROFILE: '/profile',
  ROUTE_PROFILE_BASIC_INFO: '/profile/basic_info',
  ROUTE_PROFILE_APPEARANCE: '/profile/appearance',
  ROUTE_PROFILE_ORGANIZATIONS: '/profile/organizations',

  // Home routes
  ROUTE_HOME: '/home',
  ROUTE_HOME_ONBOARDING: '/home/onboarding',
  ROUTE_HOME_ONBOARDING_NEW_ORGANIZATION: '/home/onboarding/new_organization',

  ROUTE_HOME_OVERVIEW: '/home/overview',
  ROUTE_HOME_OVERVIEW_MAP: '/home/overview/map',

  ROUTE_HOME_FLOWS: '/home/flows',
  ROUTE_HOME_FLOW: '/home/flow/:uuid',
  ROUTE_HOME_FLOW_OVERVIEW: '/home/flow/:uuid/overview',
  ROUTE_HOME_FLOW_INSTANCES: '/home/flow/:uuid/instances',
  ROUTE_HOME_FLOW_RAW_LOGS: '/home/flow/:uuid/raw_logs',

  ROUTE_HOME_ASSISTENT: '/home/assistent',
  ROUTE_HOME_INVENTORY: '/home/inventory',
  ROUTE_HOME_BILLING: '/home/billing',
  ROUTE_HOME_SETTINGS: '/home/settings',

  ROUTE_HOME_ROLES: '/home/roles',
  ROUTE_HOME_ROLE: '/home/role/:uuid',

  ROUTE_HOME_USERS: '/home/users',
  ROUTE_HOME_USERS_ACCEPT: '/home/users/accept',
  ROUTE_HOME_USERS_INVITE: '/home/users/invite',
  ROUTE_HOME_USER: '/home/user/:uuid',

  ROUTE_HOME_BEACONS: '/home/beacons',
  ROUTE_HOME_BEACON: '/home/beacon/:uuid',

  ROUTE_HOME_DEVICES: '/home/devices',
  ROUTE_HOME_DEVICE: '/home/device/:uuid',
  ROUTE_HOME_DEVICE_LOGS_ACTIVITY: '/home/device/:uuid/logs_activity',
  ROUTE_HOME_DEVICE_LOGS_STATE: '/home/device/:uuid/logs_state',
  ROUTE_HOME_DEVICE_HISTORY_LOCATION: '/home/device/:uuid/history_location',
  ROUTE_HOME_DEVICE_HISTORY_STATISTICS: '/home/device/:uuid/history_statistics',

  ROUTE_HOME_SITES: '/home/sites',
  ROUTE_HOME_SITE: '/home/site/:uuid',

  ROUTE_HOME_NOTIFICATIONS: '/home/notifications',
  ROUTE_HOME_NOTIFICATION: '/home/notification/:uuid',

  // Vigil admin routes
  ROUTE_ADMIN: '/admin',
  ROUTE_ADMIN_DEVICES: '/admin/devices',

  normalize: (pathname: string) => { return pathname.replace(/\/$/, "").replace(ROUTES.ROUTE_APP_BASE, '') },
  matches: (template: string, pathname: string) => { return matchPath(template, pathname) }
}
