import {
  Body,
  Container,
  Head,
  Heading,
  Html,
  Img,
  Link,
  Text,
  Button,
} from "@react-email/components";
import * as React from "react";
console.log(React.version)
import { Style } from './style';

export interface EmailReportTemplateProps {
  body?: string;
  buttons?: { text: string, url: string }[];
}

export const EmailReportTemplate = (props: EmailReportTemplateProps) => (
  <Html>
    <Head />
    <Body style={Style.main}>
      <Container style={Style.container}>
        <Heading style={Style.h1}>Vigil - Report</Heading>
        <Text style={{ ...Style.text, marginBottom: "14px" }}>
          {props.body || "Your Vigil report is ready. Press the button below to view it."}
        </Text>

        {props.buttons?.map((button, index) => (
          <div key={index + button.url.length + button.text.length} style={{ marginBottom: 10 }}>
            <Button
              href={button.url}
              style={Style.button}
            >
              {button.text}
            </Button>
          </div>
        ))}

        <Img
          src={`https://app-web.acc.vigil.tiental.com/static_content/emails/logo.png`}
          width="32"
          height="32"
          alt="Vigil Logo"
        />
        <Text style={Style.footer}>
          <Link
            href="https://app-website.acc.vigil.tiental.com/"
            target="_blank"
            style={{ ...Style.link, color: "#898989" }}
          >
            VigilSecure.net
          </Link>
          , the next generation platform
          <br />
          for private security.
        </Text>
      </Container>
    </Body>
  </Html>
);

EmailReportTemplate.PreviewProps = {
  body: "Your Vigil report is ready. Press the button below to view it.",
  buttons: [{ text: "View Report", url: "https://app-web.acc.vigil.tiental.com/" }]
} as EmailReportTemplateProps;

export default EmailReportTemplate;
