import React, { useContext, useEffect, useState } from 'react';
import { useModal } from '../../hooks/use_modal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { InputButton } from '../../components/input_button';
import { IconPencilSquareSolid, IconTrashSolid } from '../../components/icons';
import { ROUTES } from '../../router/routes';
import { IFlowSelect } from 'vigil-datamodel';
import { InputText } from '../../components/input_text';
import { VALIDATORS, validate } from '../../validation';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextOrganization } from '../../providers/provider_organization';
import { ModalDeleteMany } from './modal_delete_many';
import { ModalUpdateOne } from './modal_update_one';
import { FlowContextWebApp, FlowCron, FlowParameter, VIGIL_FLOWS } from 'vigil-flow';
import { FlowParameterArrayInput, FlowParameterBooleanInput, FlowParameterCronInput, FlowParameterFloatInput, FlowParameterIntegerInput, formatParametersLabel } from './func_flow/flow_inputs';
import { FlowParameterStringInput } from './func_flow/flow_inputs';
import { SDKOrganizationImpl } from '../../sdks/sdk_organization';
import { FullPageLoader } from '../../components/full_page_loader';

interface ScreenHomeFlowOverviewProps { }

type FlowParameterWithValues = FlowParameter<any> & { value: string | number | boolean | FlowCron | Array<any> };

export const ScreenHomeFlowOverview: React.FC<ScreenHomeFlowOverviewProps> = (props) => {
  const { flowParent, fetchFlowParent } = useOutletContext<{
    flowParent: IFlowSelect,
    fetchFlowParent: () => void
  }>();
  const flow = VIGIL_FLOWS.find(f => f.config.id === flowParent.id);

  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);
  const navigate = useNavigate();

  const [flowContext, setFlowContext] = useState<FlowContextWebApp | null>(null);
  const [stateFlowModify, setFlowModify] = useState<IFlowSelect>(flowParent);
  const [stateFlowParameters, setFlowParameters] = useState<FlowParameterWithValues[]>(
    flow?.config.parameters.map((parameter: any) => {
      const currentValue = flowParent.parameterValues[parameter.id];
      return { ...parameter, value: currentValue };
    }) ?? []
  );

  const { isOpen: isOpenModalDeleteFlow, toggle: toggleModalDeleteFlow } = useModal();
  const { isOpen: isOpenModalUpdateFlow, toggle: toggleModalUpdateFlow } = useModal();

  /* State */
  useEffect(() => {
    if (!organization.data) return;
    setFlowContext({ organization: new SDKOrganizationImpl(organization.data, vigil) });
  }, [organization.data]);

  /* UI Updates */
  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setFlowModify({
      ...stateFlowModify,
      name: event.target.value
    });
  }

  function onChangeParameter(parameter: FlowParameterWithValues) {
    setFlowModify({
      ...stateFlowModify,
      parameterValues: {
        ...stateFlowModify.parameterValues,
        [parameter.id]: parameter.value as any
      }
    });
  }

  async function _deleteFlow() {
    if (!organization.data) return;
    await vigil.functions.deleteFlows({
      uuidOrganization: organization.data.uuid,
      uuids: [flowParent.uuid]
    })
  }

  async function _updateFlow() {
    if (!organization.data || !flowContext) throw new Error('Organization not found');
    await vigil.functions.updateFlow({
      uuidOrganization: organization.data.uuid,
      uuid: stateFlowModify.uuid,
      data: {
        ...stateFlowModify,
        parametersLabel: await formatParametersLabel(stateFlowParameters, flowContext)
      }
    });
  }

  /* Validation */
  function validateName() {
    if (!stateFlowModify.name) return [];
    return validate(stateFlowModify.name, [VALIDATORS.length('Flow name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (JSON.stringify(stateFlowModify) === JSON.stringify(flowParent)) {
      required.push('No changes detected')
    }
    return [
      ...required,
      ...validateName(),
    ];
  }

  if (!flowContext) return <FullPageLoader />

  return (
    <div className='py-2'>
      <ModalDeleteMany
        isOpen={isOpenModalDeleteFlow}
        toggle={toggleModalDeleteFlow}
        type='flows'
        data={[{ uuid: flowParent.uuid, label: flowParent.name }]}
        onSubmit={async () => navigate(ROUTES.ROUTE_HOME_DEVICES)}
        deleteCallback={_deleteFlow}
      />
      <ModalUpdateOne
        isOpen={isOpenModalUpdateFlow}
        toggle={toggleModalUpdateFlow}
        updateCallback={_updateFlow}
        onSubmit={async () => { fetchFlowParent(); }}
      />

      <div className='rounded-t-xl bg-base-300 p-2'>
        <div className='flex items-center'>
          <div className='font-semibold flex flex-grow'>Overview</div>
          <InputButton
            text='Save'
            before={<IconPencilSquareSolid className='h-5 mr-1' />}
            type='btn-primary'
            size='btn-sm'
            disabled={validateForm().length > 0}
            onClick={toggleModalUpdateFlow}
          />
          <div className='w-2'></div>
          <InputButton
            text='Delete'
            before={<IconTrashSolid className='h-5 mr-1' />}
            type='btn-error'
            size='btn-sm'
            loading={false}
            onClick={toggleModalDeleteFlow}
          />
        </div>
      </div>

      <div className='rounded-b-xl bg-base-200 p-2 flex-grow'>
        <div className="flex flex-wrap">
          <InputText
            className="w-60 mb-2 mr-4"
            labelText='Flow name'
            value={stateFlowModify.name}
            onChange={onChangeName}
            errors={validateName()}
          />
          <div className="w-60 mb-2 mr-4">
            <label className="label">
              <span className="label-text">Flow Type</span>
            </label>
            <input
              type="text"
              className="input input-bordered w-full"
              value={flow?.config.name || ''}
              disabled
            />
          </div>
        </div>

        {/* Display flow parameters */}
        <div className="mt-4">
          <div className="font-bold text-m mb-2">Description</div>
          <div className="text-sm mb-4">{flow?.config.description}</div>
          <div className="font-bold text-m mb-2">Parameters</div>
          <div className="overflow-y-auto">
            {stateFlowParameters.map((parameter) => {
              const key = parameter.id + parameter.type;
              if (parameter.type == 'string') return <FlowParameterStringInput
                key={key}
                parameter={parameter}
                stateFlowParameters={stateFlowParameters}
                setFlowParameters={setFlowParameters}
                onChange={onChangeParameter}
                className='bg-base-200 p-2 rounded-lg mb-2 mr-2'
              />
              if (parameter.type == 'cron') return <FlowParameterCronInput
                key={key}
                parameter={parameter}
                stateFlowParameters={stateFlowParameters}
                setFlowParameters={setFlowParameters}
                onChange={onChangeParameter}
                className='bg-base-200 p-2 rounded-lg mb-2 mr-2'
              />
              if (parameter.type == 'integer') return <FlowParameterIntegerInput
                key={key}
                parameter={parameter}
                stateFlowParameters={stateFlowParameters}
                setFlowParameters={setFlowParameters}
                onChange={onChangeParameter}
                className='bg-base-200 p-2 rounded-lg mb-2 mr-2'
              />
              if (parameter.type == 'float') return <FlowParameterFloatInput
                key={key}
                parameter={parameter}
                stateFlowParameters={stateFlowParameters}
                setFlowParameters={setFlowParameters}
                onChange={onChangeParameter}
                className='bg-base-200 p-2 rounded-lg mb-2 mr-2'
              />
              if (parameter.type == 'boolean') return <FlowParameterBooleanInput
                key={key}
                parameter={parameter}
                stateFlowParameters={stateFlowParameters}
                setFlowParameters={setFlowParameters}
                onChange={onChangeParameter}
                className='bg-base-200 p-2 rounded-lg mb-2 mr-2'
              />
              if (parameter.type == 'array') return <FlowParameterArrayInput
                key={key}
                parameter={parameter}
                stateFlowParameters={stateFlowParameters}
                setFlowParameters={setFlowParameters}
                onChange={onChangeParameter}
                className='bg-base-200 p-2 rounded-lg mb-2 mr-2'
                context={flowContext}
              />
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  )
};
