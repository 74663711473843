import { sqliteTable, text, uniqueIndex } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { relations } from "drizzle-orm";
import { flowInstances } from "./bean_flow_instance";
import { users } from "./bean_user";

export const linkFlowInstanceUser = sqliteTable('link_flow_instance_user', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidUser: text('uuid_user').notNull().references(() => users.uuid),
  uuidFlowInstance: text('uuid_flow_instance').notNull().references(() => flowInstances.uuid),
}, (table) => ({
  flowInstanceUserIdx: uniqueIndex('flow_instance_user_idx').on(table.uuidOrganization, table.uuidUser, table.uuidFlowInstance)
}));

export const linkFlowInstanceUserRelations = relations(linkFlowInstanceUser, ({ one }) => ({
  organization: one(organizations, {
    fields: [linkFlowInstanceUser.uuidOrganization],
    references: [organizations.uuid],
  }),
  user: one(users, {
    fields: [linkFlowInstanceUser.uuidUser],
    references: [users.uuid],
  }),
  flowInstance: one(flowInstances, {
    fields: [linkFlowInstanceUser.uuidFlowInstance],
    references: [flowInstances.uuid],
  }),
}));