import { useContext, useEffect, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { InputSelect } from "../../../components/input_select";
import { IFlowSelect } from "vigil-datamodel";
import { FlowConfig, FlowContextWebApp, VIGIL_FLOWS } from "vigil-flow";
import { InputButton } from "../../../components/input_button";
import { useStateCallback } from "../../../hooks/use_state_callback";
import { FlowParameterArrayInput, FlowParameterBooleanInput, FlowParameterCronInput, FlowParameterFloatInput, FlowParameterIntegerInput, FlowParameterStringInput, FlowParameterWithValues, formatParametersLabel, getDefaultFlowParameterValue } from "./flow_inputs";
import { SDKOrganizationImpl } from "../../../sdks/sdk_organization";
import { FullPageLoader } from "../../../components/full_page_loader";

interface ModalFlowCreateProps extends ModalProps {
  onSubmit?: (flow: IFlowSelect) => Promise<void>;
}

export const ModalFlowCreate: React.FC<ModalFlowCreateProps> = (props) => {
  let allFlows = VIGIL_FLOWS;

  const flowOptions = allFlows.map((flow) => ({ value: flow.config.id, label: flow.config.name }));

  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [flowContext, setFlowContext] = useState<FlowContextWebApp | null>(null);
  const [stateName, setName] = useState<string>('');
  const [stateFlow, setFlow] = useState<FlowConfig<any>>(allFlows[0]);
  const [stateFlowParameters, setFlowParameters] = useState<FlowParameterWithValues[]>(allFlows[0].config.parameters.map((parameter: any) => {
    return { ...parameter, value: getDefaultFlowParameterValue(parameter) }
  }));

  /* State */
  useEffect(() => {
    if (!organization.data) return;
    setFlowContext({ organization: new SDKOrganizationImpl(organization.data, vigil) });
  }, [organization.data]);

  const createFlow = useStateCallback(async () => {
    if (!organization.data || !flowContext) return;
    const flow = await vigil.functions.createFlow({
      data: {
        uuidOrganization: organization.data.uuid,
        name: stateName,
        id: stateFlow.config.id,
        parameterValues: Object.fromEntries(stateFlowParameters.map(parameter =>
          [parameter.id, parameter.value as any]
        )),
        parametersLabel: await formatParametersLabel(stateFlowParameters, flowContext)
      }
    });
    props.onSubmit && await props.onSubmit(flow);
    props.toggle();
    resetState();
  })

  /* Validation */
  function validateName() {
    if (!stateName) return [];
    return validate(stateName, [VALIDATORS.length('Flow name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!stateName) { required.push('Flow name is required') }
    return [
      ...required,
      ...validateName(),
    ];
  }

  /* Functions */
  function resetState() {
    setName('');
    setFlow(allFlows[0]);
    setFlowParameters(allFlows[0].config.parameters.map((parameter: any) => {
      return { ...parameter, value: getDefaultFlowParameterValue(parameter) }
    }));
  }

  /* UI Updates */
  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function onChangeFlow(event: React.ChangeEvent<HTMLSelectElement>) {
    const flow = allFlows.find(flow => flow.config.id === event.target.value);
    if (flow) {
      setFlow(flow);
      setFlowParameters(flow.config.parameters.map((parameter: any) => {
        return { ...parameter, value: getDefaultFlowParameterValue(parameter) }
      }));
    }
  }

  if (!flowContext) return <FullPageLoader />

  return (
    <Modal className='w-192' isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false}>
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Create new Flow</h3>
      <div className="flex flex-wrap">
        <InputText className='w-60 mr-2' labelText='Name' value={stateName} onChange={onChangeName} errors={validateName()} > </InputText>
        <InputSelect className='w-full mr-2' labelText='Flow ID' value={stateFlow.config.id} onChange={onChangeFlow} options={flowOptions} errors={[]} />
      </div>
      <div className="overflow-y-auto">
        <div className="font-bold text-m mb-2">Description</div>
        <div className="text-sm mb-4">{stateFlow.config.description}</div>
        <div className="font-bold text-m mb-2">Parameters</div>
        {stateFlowParameters.map((parameter) => {
          const key = parameter.id + parameter.type;
          if (parameter.type == 'string') return <FlowParameterStringInput
            key={key}
            parameter={parameter}
            stateFlowParameters={stateFlowParameters}
            setFlowParameters={setFlowParameters}
            className='bg-base-200 p-2 rounded-lg mb-2 mr-2'
          />
          if (parameter.type == 'cron') return <FlowParameterCronInput
            key={key}
            parameter={parameter}
            stateFlowParameters={stateFlowParameters}
            setFlowParameters={setFlowParameters}
            className='bg-base-200 p-2 rounded-lg mb-2 mr-2'
          />
          if (parameter.type == 'integer') return <FlowParameterIntegerInput
            key={key}
            parameter={parameter}
            stateFlowParameters={stateFlowParameters}
            setFlowParameters={setFlowParameters}
            className='bg-base-200 p-2 rounded-lg mb-2 mr-2'
          />
          if (parameter.type == 'float') return <FlowParameterFloatInput
            key={key}
            parameter={parameter}
            stateFlowParameters={stateFlowParameters}
            setFlowParameters={setFlowParameters}
            className='bg-base-200 p-2 rounded-lg mb-2 mr-2'
          />
          if (parameter.type == 'boolean') return <FlowParameterBooleanInput
            key={key}
            parameter={parameter}
            stateFlowParameters={stateFlowParameters}
            setFlowParameters={setFlowParameters}
            className='bg-base-200 p-2 rounded-lg mb-2 mr-2'
          />
          if (parameter.type == 'array') return <FlowParameterArrayInput
            key={key}
            parameter={parameter}
            stateFlowParameters={stateFlowParameters}
            setFlowParameters={setFlowParameters}
            className='bg-base-200 p-2 rounded-lg mb-2 mr-2'
            context={flowContext}
          />
          return null
        })}
      </div>
      {createFlow.error && <StatusAlert message={createFlow.error} type={"alert-error"} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Confirm' loading={createFlow.loading} disabled={validateForm().length > 0} type='btn-primary' onClick={async () => await createFlow.call()}></InputButton>
      </div>
    </Modal>
  )
}