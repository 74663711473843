import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { ROUTES } from '../../router/routes';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';
import { IFlowSelect } from 'vigil-datamodel';
import { ContextOrganization } from '../../providers/provider_organization';
import { IconDocumentMagnifyingGlass, IconInboxStack, IconSquare3Stack } from '../../components/icons';
import { Tabs } from '../../components/tabs';

interface ScreenHomeFlowProps { }

export const ScreenHomeFlow: React.FC<ScreenHomeFlowProps> = (props) => {
  const navigate = useNavigate();
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const params = useParams();
  const uuidFlow = params['uuid'] || '';

  const [stateError, setError] = useState<string>('');
  const [stateFlowLoading, setFlowLoading] = useState(true);
  const [stateFlow, setFlow] = useState<IFlowSelect>();

  useEffect(() => { fetchFlow(); }, [uuidFlow]);

  async function fetchFlow() {
    try {
      if (!organization.data?.uuid) throw new Error('Organization not found');
      setFlowLoading(true);
      const flow = await vigil.functions.findOneFlow({ uuidOrganization: organization.data.uuid, uuid: uuidFlow });
      setFlow(flow);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setFlowLoading(false);
    }
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  function handleTabSelect(id: string) {
    navigate(`${ROUTES.ROUTE_HOME_FLOW.replace(':uuid', uuidFlow)}/${id}`);
  }

  if (stateFlowLoading || !stateFlow) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 w-full h-full'>
      <Breadcrumbs
        crumbs={[
          {
            id: 'flows',
            onClick: () => navigate(ROUTES.ROUTE_HOME_FLOWS),
            text: 'Flows'
          },
          {
            id: uuidFlow,
            onClick: () => { },
            text: stateFlow.name
          }
        ]}
      />

      <Tabs 
        selectedId={getSelectedId()} 
        onSelect={handleTabSelect} 
        items={[
          {
            icon: IconDocumentMagnifyingGlass,
            id: 'overview',
            text: 'Overview'
          },
          {
            icon: IconInboxStack,
            id: 'instances',
            text: 'Instances'
          },
          {
            icon: IconSquare3Stack,
            id: 'raw_logs',
            text: 'Raw Logs'
          }
        ]} 
      />

      <div className='mt-2 w-full flex-grow'>
        <Outlet context={{ flowParent: stateFlow, fetchFlowParent: fetchFlow }} />
      </div>
    </div>
  )
};
