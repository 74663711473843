import { sqliteTable, text, uniqueIndex } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { relations } from "drizzle-orm";
import { devices } from "./bean_device";
import { flows } from "./bean_flow";

export const linkFlowDevice = sqliteTable('link_flow__device', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidDevice: text('uuid_device').notNull().references(() => devices.uuid),
  uuidFlow: text('uuid_flow_').notNull().references(() => flows.uuid),
}, (table) => ({
  flowDeviceIdx: uniqueIndex('flow__device_idx').on(table.uuidOrganization, table.uuidDevice, table.uuidFlow)
}));

export const linkFlowDeviceRelations = relations(linkFlowDevice, ({ one }) => ({
  organization: one(organizations, {
    fields: [linkFlowDevice.uuidOrganization],
    references: [organizations.uuid],
  }),
  device: one(devices, {
    fields: [linkFlowDevice.uuidDevice],
    references: [devices.uuid],
  }),
  flow: one(flows, {
    fields: [linkFlowDevice.uuidFlow],
    references: [flows.uuid],
  }),
}));