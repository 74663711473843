import { FlowConfig } from "../base/config";

export const FLOW_DEVICE_USER_SIGN_OUT_ID = "device_user_sign_out"
const TAG_SIGN_OUT = "sign_out";

export interface FlowDeviceUserSignOutParameters {
  schedule: "0 12 * * *";
  duration_hours: number;
  site_uuids: string[];
  device_uuids: string[];
}

export const FlowDeviceUserSignOut: FlowConfig<FlowDeviceUserSignOutParameters> = {
  config: {
    id: FLOW_DEVICE_USER_SIGN_OUT_ID,
    name: "Device User Sign Out",
    description: "Flow to sign a user out, if signed in. Note: You need to atleast provide one site or device or both, if none is provided, the flow will not run.",
    parameters: [
      {
        id: "schedule",
        name: "Schedule",
        description: "Schedule to sign out the user",
        required: true,
        type: "cron",
        memberLink: null
      },
      {
        id: "duration_hours",
        name: "Duration (hours)",
        description: "For how long will we check if the user is signed in",
        required: true,
        type: "integer",
        memberLink: null
      },
      {
        id: "site_uuids",
        name: "(Optional) Sites",
        description: "List of sites that this flow should be linked to",
        required: true,
        type: "array",
        validation: {
          duplicateItems: false,
          options: async (context) => {
            return await context.organization.getSites().then(sites => sites.map(site => ({ label: site.name, value: site.uuid })))
          }
        },
        memberLink: 'site'
      },
      {
        id: "device_uuids",
        name: "(Optional) Devices",
        description: "List of devices that this flow should be linked to",
        required: true,
        type: "array",
        validation: {
          duplicateItems: false,
          options: async (context) => {
            return await context.organization.getDevices().then(devices => devices.map(device => ({ label: device.serial, value: device.uuid })))
          }
        },
        memberLink: 'device'
      }
    ],
  },
  device: {
    screens: [],
    createInstance: async (context, parameters) => {
      const { vigil, flow, device } = context;

      // If device_uuids is provided, check if this device is in the list
      const _device = await device.getDevice()
      if (!parameters.device_uuids.includes(_device.uuid)) return null

      // Create the flow instance if it should run now
      const timeslot = vigil.getTimeslotFromCron(parameters.schedule, parameters.duration_hours * 60 * 60 * 1000);
      const flowInstance = await flow.getInstance(timeslot.start, timeslot.end, new Date())
      if (!flowInstance) return null
      
      await flowInstance.linkDevices([_device.uuid])
      await flowInstance.linkSites(parameters.site_uuids)

      return flowInstance
    },
    createInstanceFromAnotherFlow: async (context, parameters) => {
      const { vigil, flow, device } = context;

      // If device_uuids is provided, check if this device is in the list
      const _device = await device.getDevice()
      if (!parameters.device_uuids.includes(_device.uuid)) return null

      // Create the flow instance if it should run now
      const timeslot = vigil.getTimeslotFromCron(parameters.schedule, parameters.duration_hours * 60 * 60 * 1000);
      const flowInstance = await flow.getInstance(timeslot.start, timeslot.end, new Date())
      if (!flowInstance) return null
      
      await flowInstance.linkDevices([_device.uuid])
      await flowInstance.linkSites(parameters.site_uuids)
      await flowInstance.updateParameters(parameters)

      return flowInstance
    },
    tickInstance: async (context, flowInstance) => {
      // Destructure context
      const { device } = context;

      // If user is signed out, skip tick
      if (!await device.getUser()) return

      // If user is signed in, sign out
      if (await device.getUser()) {
        await device.signOutUser()

        // Create log
        await flowInstance.createLog({ msg: "Sign out" }, [TAG_SIGN_OUT])
      }
    },
  },
  server: {
    createInstance: async (context, parameters) => {
      const { vigil, flow, device } = context;

      // If device_uuids is provided, check if this device is in the list
      const _device = await device.getDevice()
      if (!parameters.device_uuids.includes(_device.uuid)) return null

      // Create the flow instance if it should run now
      const timeslot = vigil.getTimeslotFromCron(parameters.schedule, parameters.duration_hours * 60 * 60 * 1000);
      const flowInstance = await flow.getInstance(timeslot.start, timeslot.end, new Date())
      if (!flowInstance) return null
      
      await flowInstance.linkDevices([_device.uuid])
      await flowInstance.linkSites(parameters.site_uuids)

      return flowInstance
    },
    createInstanceFromAnotherFlow: async (context, parameters) => {
      const { vigil, flow, device } = context;

      // If device_uuids is provided, check if this device is in the list
      const _device = await device.getDevice()
      if (!parameters.device_uuids.includes(_device.uuid)) return null

      // Create the flow instance if it should run now
      const timeslot = vigil.getTimeslotFromCron(parameters.schedule, parameters.duration_hours * 60 * 60 * 1000);
      const flowInstance = await flow.getInstance(timeslot.start, timeslot.end, new Date())
      if (!flowInstance) return null
      
      await flowInstance.linkDevices([_device.uuid])
      await flowInstance.linkSites(parameters.site_uuids)
      await flowInstance.updateParameters(parameters)

      return flowInstance
    },
    tickInstance: async (context, flowInstance) => {
      // Destructure context
      const { device } = context;

      // If user is signed out, skip tick
      if (!await device.getUser()) return

      const now = new Date();

      // If user has not signed out for 10 minutes, send notification
      const lastLog = await flowInstance.getServerLastCreatedLog([TAG_SIGN_OUT])
      if (now > new Date(flowInstance.dateTimeStart.valueOf() + 10 * 60 * 1000) && (!lastLog || now > new Date(lastLog.datetime.valueOf() + 10 * 60 * 1000))) {
        // Create log
        await flowInstance.createLog({ msg: "Sign out missed" }, [TAG_SIGN_OUT])
      }
    }
  }
}
