import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { BeaconType, IBeaconSelect, ISiteSelect, Permissions } from 'vigil-datamodel';
import { ROUTES } from '../../router/routes';
import { InputButton } from '../../components/input_button';
import { IconPencilSquareSolid, IconTrashSolid, IconPlusSolid, IconLinkSolid, IconChevronRightSolid, IconBluetoothOutline, IconMapPinAltOutline, IconNFCOutline, IconQrCodeSolid, IconWifiOutline, IconBrokenLinkOutline } from '../../components/icons';
import { useModal } from '../../hooks/use_modal';
import { ModalBeaconCreate } from './func_beacon/modal_beacon_create';
import { ModalSiteUpdate } from './func_site/modal_site_update';
import { ModalSiteBeaconsCreate } from './func_beacon_site/modal_site_beacons_create';
import { ModalSiteBeaconsDelete } from './func_beacon_site/modal_site_beacons_delete';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';
import { ContextTheme } from '../../providers/provider_theme';
import useBreakpoints from '../../hooks/use_breakpoints';
import { getBoundingBox, VigilMap, VigilMapBeacon, VigilMapController, VigilMapMessageId, VigilMapV2ThemeId } from 'vigil-map';
import { VigilMapV2Props } from 'vigil-map';
import { TTuuid } from 'tt-uuid';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextOrganization } from '../../providers/provider_organization';
import { ModalDeleteMany } from './modal_delete_many';
import { useFetch } from '../../hooks/use_fetch';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';

export const ScreenHomeSite: React.FC = () => {
  const navigate = useNavigate();
  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);
  const contextRoles = useContext(ContextRoles);
  const theme = useContext(ContextTheme);
  const breakpoints = useBreakpoints();

  const refWatchId = useRef<number | null>(null)

  const [stateSelectedBeacon, setSelectedBeacon] = useState<IBeaconSelect>();

  const [stateSnapped, setSnapped] = useState(false);
  const [stateMapController, setMapController] = useState<VigilMapController | null>(null);
  const [stateMapProps, setMapProps] = useState<VigilMapV2Props>({});
  const [stateBeaconMarkers, setBeaconMarkers] = useState<VigilMapBeacon[]>([]);

  const { isOpen: isOpenModalUpdateSite, toggle: toggleModalUpdateSite } = useModal();
  const { isOpen: isOpenModalDeleteSite, toggle: toggleModalDeleteSite } = useModal();
  const { isOpen: isOpenModalCreateBeacon, toggle: toggleModalCreateBeacon } = useModal();
  const { isOpen: isOpenModalDeleteSiteBeacons, toggle: toggleModalDeleteSiteBeacons } = useModal();
  const { isOpen: isOpenModalCreateSiteBeacons, toggle: toggleModalCreateSiteBeacons } = useModal();

  const params = useParams();
  const uuidSite = params['uuid'] || '';

  /* State */
  const site = useFetch({
    callback: async () => {
      if (!organization.data) return
      return await vigil.functions.findOneSite({ uuidOrganization: organization.data.uuid, uuid: uuidSite })
    },
  });

  const beacons = useFetch({
    callback: async () => {
      if (!organization.data) return
      return await vigil.functions.findManyBeaconsLinkedToSite({ uuidOrganization: organization.data.uuid, uuidSite: uuidSite })
    },
  });

  useEffect(() => {
    if (refWatchId.current && breakpoints['MD']) return
    const watchOptions = { enableHighAccuracy: true, timeout: 5000, maximumAge: 30000 }
    
    const errorCallback = (error: GeolocationPositionError) => { 
      console.error("Error getting location:", error);
      // Retry after 10 seconds
      setTimeout(() => {
        navigator.geolocation.getCurrentPosition(updateUserLocation, errorCallback, watchOptions);
      }, 10000);
    }

    // Get current location
    navigator.geolocation.getCurrentPosition(updateUserLocation, errorCallback, watchOptions);

    // Watch for location changes
    refWatchId.current = navigator.geolocation.watchPosition(updateUserLocation, errorCallback, watchOptions);

    return () => { if (refWatchId.current) clearWatchLocationId(refWatchId.current) };
  }, [stateBeaconMarkers, breakpoints]);

  useEffect(() => {
    if (!beacons.result) return
    // TODO: Need to finish this, so that we can unlink beacons from the map
    setBeaconMarkers(beacons.result.map((beacon) => ({
      uuid: beacon.uuid,
      lat: beacon.latitude,
      lng: beacon.longitude,
      name: beacon.name,
      color: 'red',
      additionalInfo: `<div>
          UUID: ${beacon.uuid}<br/>
          Type: ${beacon.type}<br/>
        </div>`
    })));
  }, [beacons]);

  useEffect(() => {
    setMapProps({
      beacons: stateBeaconMarkers,
      zoomControl: breakpoints['MD'] ? true : false,
      theme: theme.data === 'dark' ? VigilMapV2ThemeId.DARK_MATTER : VigilMapV2ThemeId.BASIC,
    });
    if (stateMapController && stateBeaconMarkers.length > 0 && !stateSnapped) {
      if (breakpoints['MD']) {
        stateMapController.sendVigilMessage({
          id: VigilMapMessageId.FIT_BOUNDS,
          bounds: getBoundingBox(stateBeaconMarkers.map((beacon) => ({ lat: beacon.lat, lng: beacon.lng }))),
          options: {
            maxZoom: 18,
            padding: {
              left: 350,
            }
          }
        });
      } else {
        stateMapController.sendVigilMessage({
          id: VigilMapMessageId.FIT_BOUNDS,
          bounds: getBoundingBox(stateBeaconMarkers.map((beacon) => ({ lat: beacon.lat, lng: beacon.lng }))),
          options: {
            maxZoom: 18,
          }
        });
      }
      setSnapped(true);
    }
  }, [stateBeaconMarkers, stateMapController, breakpoints, theme]);

  /* Functions */
  async function updateUserLocation(position: GeolocationPosition) {
    setMapProps({
      zoomControl: breakpoints['MD'] ? true : false,
      theme: theme.data === 'dark' ? VigilMapV2ThemeId.DARK_MATTER : VigilMapV2ThemeId.BASIC,
      agentInfo: { lat: position.coords.latitude, lng: position.coords.longitude },
      beacons: stateBeaconMarkers,
    })
  }

  async function clearWatchLocationId(watchId: number) {
    navigator.geolocation.clearWatch(watchId);
  }

  function toggleSelectedBeacon(beacon?: IBeaconSelect) {
    if (stateSelectedBeacon === beacon || !beacon) {
      setSelectedBeacon(undefined);
      beacon = undefined;
      stateMapController?.sendVigilMessage({
        id: VigilMapMessageId.FIT_BOUNDS,
        bounds: getBoundingBox(stateBeaconMarkers.map((beacon) => ({ lat: beacon.lat, lng: beacon.lng }))),
        options: {
          maxZoom: 18,
          padding: {
            left: 350,
          }
        }
      });
    } else {
      setSelectedBeacon(beacon);
      stateMapController?.sendVigilMessage({
        id: VigilMapMessageId.FIT_BOUNDS,
        bounds: getBoundingBox(stateBeaconMarkers.map((beacon) => ({ lat: beacon.lat, lng: beacon.lng }))),
        options: {
          maxZoom: 18,
          padding: {
            left: 600,
          }
        }
      });
    }

    const tempBeaconMarkers = stateBeaconMarkers.map((beaconMarker) => ({
      ...beaconMarker,
      color: beaconMarker.uuid === beacon?.uuid ? 'green' : 'red'
    }));
    setBeaconMarkers(tempBeaconMarkers as VigilMapBeacon[]);
  }

  async function _deleteSite() {
    if (!organization.data) return;
    await vigil.functions.deleteSites({
      uuidOrganization: organization.data.uuid,
      uuids: [uuidSite]
    })
  }

  if (site.loading || beacons.loading) return <FullPageLoader />;
  if (site.error || beacons.error) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={site.error || beacons.error} type='alert-error' /></div>;
  if (!site.result || !beacons.result) return <div className='px-8 py-4 h-full w-full'><StatusAlert message='No site or beacons found' type='alert-error' /></div>;

  return (
    <div className='pt-2 w-full flex flex-col h-full'>
      <ModalSiteUpdate isOpen={isOpenModalUpdateSite} toggle={toggleModalUpdateSite} onSubmit={async () => await site.refetch()} uuidSite={uuidSite} />
      <ModalDeleteMany
        isOpen={isOpenModalDeleteSite}
        toggle={toggleModalDeleteSite}  
        type='sites'
        data={[{ uuid: site.result.uuid || '', label: site.result.name || '' }]}
        onSubmit={async () => navigate(ROUTES.ROUTE_HOME_SITES)}
        extraComponents={<li>Remove the site-beacon links, but not the beacons.</li>}
        deleteCallback={async () => _deleteSite()}
      />
      <ModalBeaconCreate isOpen={isOpenModalCreateBeacon} toggle={toggleModalCreateBeacon} uuidSite={uuidSite} onSubmit={async () => await beacons.refetch()} />
      <ModalSiteBeaconsDelete isOpen={isOpenModalDeleteSiteBeacons} toggle={toggleModalDeleteSiteBeacons} uuidSite={uuidSite} uuidBeacons={stateSelectedBeacon ? [stateSelectedBeacon.uuid] : []} onSubmit={async () => {
        await beacons.refetch();
        setSelectedBeacon(undefined);
      }} />
      <ModalSiteBeaconsCreate isOpen={isOpenModalCreateSiteBeacons} toggle={toggleModalCreateSiteBeacons} uuidSite={uuidSite} onSubmit={async () => await beacons.refetch()} />

      <div className='flex items-center overflow-x-auto'>
        <div className='flex flex-grow items-end'>
          <div className="flex items-center mr-6">
            <span className='font-semibold'>Date Created:</span>
            <span className="text-sm ml-2">{TTuuid.decodeCuuid(site.result.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(site.result.uuid).time.toLocaleTimeString()}</span>
          </div>
          <div className="flex items-center mr-6">
            <span className='font-semibold'>Last Updated:</span>
            <span className="text-sm ml-2">{TTuuid.decodeCuuid(site.result.changeStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(site.result.changeStamp).time.toLocaleTimeString()}</span>
          </div>
        </div>
        <div className='flex space-x-3'>
          <div className='md:hidden flex space-x-3'>
            <InputButton text='Create Beacon' before={<IconPlusSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.C, Permissions.RBeacon)} onClick={toggleModalCreateBeacon} />
            <InputButton text='Link Beacon' before={<IconLinkSolid className='h-5 mr-2' />} type='btn-secondary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.C, Permissions.RBeaconSiteLink)} onClick={toggleModalCreateSiteBeacons} />
          </div>
          <InputButton text='Edit Site' before={<IconPencilSquareSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.U, Permissions.RSite)} onClick={toggleModalUpdateSite} />
          <InputButton text='Delete Site' before={<IconTrashSolid className='h-5 mr-2' />} type='btn-error' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.D, Permissions.RSite)} onClick={toggleModalDeleteSite} />
        </div>
      </div>

      {/* Mobile view */}
      {!breakpoints['MD'] && (
        <div className="card drop-shadow-md flex flex-col flex-grow py-2">
          <div className='flex-grow rounded-xl overflow-hidden'>
            <VigilMap setMapController={setMapController} state={stateMapProps} />
          </div>
        </div>
      )}

      {/* Desktop view */}
      {breakpoints['MD'] && (
        <div className="card drop-shadow-md flex flex-col flex-grow py-2">
          <div className='flex-grow rounded-xl overflow-hidden'>
            <VigilMap setMapController={setMapController} state={stateMapProps} />
          </div>

          <div className="card bg-opacity-0 backdrop-blur-sm w-80 m-2 inset-0 absolute">
            <div className='rounded-t-xl bg-base-200 bg-opacity-40'>
              <div className='flex justify-center font-bold mt-2'>Linked Beacons</div>
              <div className='p-2 flex flex-row'>
                <InputButton text='Create Beacon' before={<IconPlusSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.C, Permissions.RBeacon)} onClick={toggleModalCreateBeacon} />
                <div className='mx-2'></div>
                <InputButton text='Link Beacon' before={<IconLinkSolid className='h-5 mr-2' />} type='btn-secondary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.C, Permissions.RBeaconSiteLink)} onClick={toggleModalCreateSiteBeacons} />
              </div>
            </div>

            <div className='rounded-b-xl bg-base-100 bg-opacity-20 p-2 flex-grow overflow-y-auto'>
              {beacons.result && beacons.result.map((beacon) => (
                <div key={beacon.uuid} className="relative">
                  <div onClick={() => toggleSelectedBeacon(beacon)} className={`card dropdown dropdown-right w-full hover:cursor-pointer hover:bg-base-200 flex flex-row items-center p-2 drop-shadow-xl mb-2 ${stateSelectedBeacon === beacon ? 'bg-base-200 dropdown-open' : 'bg-base-100 dropdown-close'}`}>
                    {beacon.type === BeaconType.QR && <IconQrCodeSolid className='h-5 mr-2' />}
                    {beacon.type === BeaconType.GEO && <IconMapPinAltOutline className='h-5 mr-2 stroke-base-content' />}
                    {beacon.type === BeaconType.BLE && <IconBluetoothOutline className='h-5 mr-2 stroke-base-content' />}
                    {beacon.type === BeaconType.NFC && <IconNFCOutline className='h-5 mr-2 stroke-base-content' />}
                    {beacon.type === BeaconType.WIFI && <IconWifiOutline className='h-5 mr-2' />}
                    <div className='flex flex-col flex-grow text-left'>
                      <span className='text-md font-semibold'>{beacon.name}</span>
                      <span className='text-xs'>{beacon.latitude.toFixed(5)}, {beacon.longitude.toFixed(5)}</span>
                    </div>
                    <IconChevronRightSolid className={`h-5 transform transition-transform duration-300 ${stateSelectedBeacon === beacon ? 'rotate-180' : ''}`} />
                  </div>
                </div>
              ))}
            </div>

            {stateSelectedBeacon && (
              <div onClick={(event) => event.stopPropagation()} className="absolute left-full ml-3 mt-20 p-2 shadow dropdown-content z-[1] bg-base-100 rounded-box w-64 outline outline-1 outline-neutral-content">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-1 top-1" onClick={() => toggleSelectedBeacon()}>✕</button>
                <div className='text-lg font-semibold mb-2 text-center'>{stateSelectedBeacon.name}</div>
                <div className='text-left'>
                  <div className='text-xs'>UUID: {stateSelectedBeacon.uuid}</div>
                  <div className='text-xs'>Latitude: {stateSelectedBeacon.latitude.toFixed(6)}</div>
                  <div className='text-xs'>Longitude: {stateSelectedBeacon.longitude.toFixed(6)}</div>
                  <div className='text-xs'>Altitude: {stateSelectedBeacon.altitude}m</div>
                  <div className='text-xs'>Radius: {stateSelectedBeacon.radius}m</div>
                  <div className='text-xs'>Type: {stateSelectedBeacon.type}</div>
                  <div className='text-xs'>Date created: {TTuuid.decodeCuuid(stateSelectedBeacon.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateSelectedBeacon.uuid).time.toLocaleTimeString()}</div>
                  <div className='text-xs mb-4'>Last updated: {TTuuid.decodeCuuid(stateSelectedBeacon.changeStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateSelectedBeacon.changeStamp).time.toLocaleTimeString()}</div>
                  <button
                    className="btn btn-primary btn-sm w-full"
                    onClick={() => navigate(`/home/beacon/${stateSelectedBeacon.uuid}`)}
                  >
                    Edit Beacon
                  </button>
                  <div className='mt-2' />
                  <InputButton text='Unlink Beacon' before={<IconBrokenLinkOutline className='h-4 mr-2 fill-warning-content' />} type='btn-warning' size='btn-sm' loading={false}
                    disabled={!contextRoles.hasUserPermission(TTActions.D, Permissions.RBeaconSiteLink)}
                    onClick={() => toggleModalDeleteSiteBeacons()}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};