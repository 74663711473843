import { EmailReport } from "vigil-notifications/src/emails/emails";
import { FlowConfig } from "../base/config";

interface SendLog {
  msg: string;
  site_uuids: string[];
  to_emails: string[];
}

const SEND_EMAIL_TAG = "send_email"
// TODO: Add Telegram and WhatsApp
// const SEND_TELEGRAM_TAG = "send_telegram"
// const SEND_WHATSAPP_TAG = "send_whatsapp"

interface FlowSendPublicReportParameters {
  schedule: "0 12 * * *";
  to_emails: string[];
  // TODO: Add Telegram and WhatsApp
  // telegram_chat_ids: string[];
  // whatsapp_phone_numbers: string[];
  // all_sites: boolean;
  site_uuids: string[];
}

export const FlowSendPublicReport: FlowConfig<FlowSendPublicReportParameters> = {
  config: {
    id: "send_public_report",
    name: "Send Report",
    description: "Send a link of a report via email. Note: If you make 'All Sites' true, it will override the 'Sites' parameter. If you do not select any sites, the flow will not run.",
    parameters: [
      {
        id: "schedule",
        name: "Schedule",
        description: "Schedule to send the email",
        required: true,
        type: "cron",
        memberLink: null
      },
      {
        id: "to_emails",
        name: "To Emails",
        description: "List of emails to send the report to",
        required: true,
        type: "array",
        memberLink: null,
      },
      // TODO: Add Telegram and WhatsApp
      // {
      //   id: "telegram_chat_ids",
      //   name: "Telegram Chat IDs",
      //   description: "List of Telegram chat IDs to send the report to",
      //   required: false,
      //   type: "array"
      // },
      // {
      //   id: "whatsapp_phone_numbers",
      //   name: "WhatsApp Phone Numbers",
      //   description: "List of WhatsApp phone numbers to send the report to",
      //   required: false,
      //   type: "array"
      // },
      // {
      //   id: "all_sites",
      //   name: "All Sites",
      //   description: "If true, the report will be sent for all sites, even if new sites get added in the future",
      //   required: true,
      //   type: "boolean"
      // },
      {
        id: "site_uuids",
        name: "(Optional) Sites",
        description: "List of sites to filter the report on",
        required: true,
        type: "array",
        memberLink: 'site',
        validation: {
          duplicateItems: false,
          options: async (context) => {
            return await context.organization.getSites().then(sites => sites.map(site => ({ label: site.name, value: site.uuid })))
          }
        }
      }
    ]
  },
  device: {
    screens: [],
    createInstance: async () => { return null },
    tickInstance: async () => { },
  },
  server: {
    createInstance: async (context, parameters) => {
      const { vigil, flow } = context;
      const timeslot = vigil.getTimeslotFromCron(parameters.schedule, 30 * 60 * 1000); // 30 minutes time window
      const flowInstance = await flow.getInstance(timeslot.start, timeslot.end, new Date())
      if (!flowInstance) return null

      await flowInstance.linkSites(parameters.site_uuids)

      return flowInstance
    },
    tickInstance: async (context, flowInstance) => {
      const { notification, environment } = context;

      const parameters = flowInstance.flowSnapshot.parameterValues as FlowSendPublicReportParameters;

      // If user should not be signed in, skip tick
      // const all_sites = parameters.all_sites;
      let site_uuids = parameters.site_uuids;
      // if (all_sites) site_uuids = await organization.getSites().then(sites => sites.map(site => site.uuid));
      if (!site_uuids || site_uuids.length === 0) return;

      // Get environment variables
      const URL_SERVICE_BACKEND = environment.URL_SERVICE_BACKEND;

      const lastLogEmail = await flowInstance.getServerLastCreatedLog<SendLog>([SEND_EMAIL_TAG])
      if (lastLogEmail) return

      const to_emails = parameters.to_emails;

      // Send email
      notification.sendEmails([
        new EmailReport(to_emails.join(";"), {
          buttons: [
            {
              text: "View Report",
              url: `${URL_SERVICE_BACKEND}/public/report?site_uuids=${site_uuids?.join("%2C")}`
            }
          ]
        })
      ])

      // Create log
      await flowInstance.createLog<SendLog>({ msg: "Send email report", site_uuids, to_emails }, [SEND_EMAIL_TAG])
    }
  }
}
