import { sqliteTable, text, uniqueIndex } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { relations } from "drizzle-orm";
import { flows } from "./bean_flow";
import { users } from "./bean_user";

export const linkFlowUser = sqliteTable('link_flow__user', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidUser: text('uuid_user').notNull().references(() => users.uuid),
  uuidFlow: text('uuid_flow_').notNull().references(() => flows.uuid),
}, (table) => ({
  flowUserIdx: uniqueIndex('flow__user_idx').on(table.uuidOrganization, table.uuidUser, table.uuidFlow)
}));

export const linkFlowUserRelations = relations(linkFlowUser, ({ one }) => ({
  organization: one(organizations, {
    fields: [linkFlowUser.uuidOrganization],
    references: [organizations.uuid],
  }),
  user: one(users, {
    fields: [linkFlowUser.uuidUser],
    references: [users.uuid],
  }),
  flow: one(flows, {
    fields: [linkFlowUser.uuidFlow],
    references: [flows.uuid],
  }),
}));