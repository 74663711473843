import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { linkOrganizationUsers } from "./bean_link_organization_user";
import { linkOrganizationRoleUser } from "./bean_link_organization_role_user";
import { organizationRoleUserInvite } from "./bean_organization_role_user_invite";
import { linkDeviceUserLoggedInStates } from "./bean_link_device_user_logged_in_state";
import { linkFlowInstanceUser } from "./bean_link_flow_instance_user";
import { linkFlowUser } from "./bean_link_flow_user";

export const users = sqliteTable('users', {
  ...schemaBase(),
  firstName: text('first_name').notNull(),
  lastName: text('last_name').notNull(),
  idNumber: text('id_number').unique(),
  email: text('email').unique(),
  mobile: text('mobile').unique(),
});

export const usersRelations = relations(users, ({ many }) => ({
  usersToDevicesAndLoggedInStates: many(linkDeviceUserLoggedInStates),
  usersToOrganizations: many(linkOrganizationUsers),
  usersToOrganizationsAndRoles: many(linkOrganizationRoleUser),
  usersToOrganizationRoleInvites: many(organizationRoleUserInvite),
  usersToFlowInstances: many(linkFlowInstanceUser),
  usersToFlows: many(linkFlowUser),
}));
