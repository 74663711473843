import { renderAsync } from '@react-email/render';
import EmailOTPTemplate, { EmailOTPTemplateProps } from './templates/email_otp';
import EmailInviteTemplate, { EmailInviteTemplateProps } from './templates/email_invite';
import EmailReportTemplate, { EmailReportTemplateProps } from './templates/email_report';

export class EmailBase {
    public readonly target: string;
    public readonly subject: string;
    private template: React.JSX.Element;
    constructor(target: string, subject: string, template: React.JSX.Element) {
        this.target = target;
        this.subject = subject;
        this.template = template;
    }

    public async render(plainText: boolean) {
        const html = renderAsync(this.template, {
            pretty: true,
            plainText: plainText
        });

        return html;
    }
}

export class EmailOTP extends EmailBase {
    constructor(target: string, props: EmailOTPTemplateProps) {
        super(target, 'VigilSecure OTP', EmailOTPTemplate(props))
    }
}

export class EmailInvite extends EmailBase {
    constructor(target: string, props: EmailInviteTemplateProps) {
        super(target, 'VigilSecure Invite', EmailInviteTemplate(props))
    }
}

export class EmailReport extends EmailBase {
  constructor(target: string, props: EmailReportTemplateProps) {
      super(target, 'Vigil Report', EmailReportTemplate(props))
  }
}
